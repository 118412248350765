exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-detail-[id]-tsx": () => import("./../../../src/pages/detail/[id].tsx" /* webpackChunkName: "component---src-pages-detail-[id]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakty-tsx": () => import("./../../../src/pages/kontakty.tsx" /* webpackChunkName: "component---src-pages-kontakty-tsx" */),
  "component---src-pages-novinky-tsx": () => import("./../../../src/pages/novinky.tsx" /* webpackChunkName: "component---src-pages-novinky-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-pro-lektory-tsx": () => import("./../../../src/pages/pro-lektory.tsx" /* webpackChunkName: "component---src-pages-pro-lektory-tsx" */),
  "component---src-pages-pro-ridice-tsx": () => import("./../../../src/pages/pro-ridice.tsx" /* webpackChunkName: "component---src-pages-pro-ridice-tsx" */)
}

